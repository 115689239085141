<template>
  <div>
    <div v-if="'hetun' === heTunInfo">
      <companyZj></companyZj>
    </div>
    <div v-else>
      <div class="title">公司简介</div>
      <div style="text-align: center; line-height: 20px; margin-top: 10px;">
        发布时间：2022-01-07 17:51:48
      </div>
      <div data-v-0ab10f90="" style="font-size: 14px;"><p>&nbsp;</p>
        <p>{{ webKeyword.OPEN_COM_NAME }}成立于2019年，公司通过去中心化的智慧云端生态体系赋能中小企业实现数字化转型，通过科技驱动商业革新，让商业变得更智慧!目前公司{{ webKeyword.OPEN_COM_NAME }}云平台旗下鲸旦电商SaaS平台和{{ webKeyword.OPEN_COM_NAME }}平台是公司电商团队为企业“直播+商城+分销+供应链”的电商服务一体化解决方案。帮助客户在新零售时代实现数据化转型，帮助企业提高运营效率和盈利能力。</p>
        <p>我们拥有众多海内外知名品牌及厂商授权商品，强大的采货、备货、仓配资源；专业为中小商家提供一站式的跨境商品从选品采购、订单管理、仓配物流、技术支持、运营辅导服务等环节。{{ webKeyword.OPEN_COM_NAME }}致力于为合作伙伴做好充分的赋能服务为己任!</p>
        <p>&nbsp;</p>
        <p>
          我们精心打造集海内外采购、品牌出海、品牌引进、保税仓储、国际货运、以及运营平台于一体的综合性供应链服务平台，致力于为全球商家及消费者提供专业、完整、高效的国内、国际贸易综合服务。海外仓储网络覆盖美国、英国、日本等发达国家，同时还在欧洲组建专业的采购团队；我们也同样在国内建立了完善的保税仓网络，拥有广州、深圳、江西、杭州等多个自营保税仓以及合作联营保税仓。通过{{ webKeyword.OPEN_COM_NAME }}高品质、高效率的服务，客户轻松实现“零成本、零库存、零风险”销售并赚取利润。</p>
        <p><strong>{{ webKeyword.OPEN_COM_NAME }}愿景</strong></p>
        <p style="margin-left:50px;">成为中小企业和电商创业者的首选伙伴！</p>
        <p><strong>{{ webKeyword.OPEN_COM_NAME }}定位</strong></p>
        <p>1、企业定位：深耕细作、稳健的发展。</p>
        <p>2、商品定位：品质保证、专业专注是我们的宗旨。</p>
        <p>
          3、价格定位：合理共赢，实现长期发展。</p>
        <p>
          4、文化定位：合作共赢。</p>
        <p><strong>{{ webKeyword.OPEN_COM_NAME }}特色</strong></p>
        <p>
          致力为国内消费者提供快捷、高品质、值得信赖的国外正品购物体验。</p>
        <p>正品保证：深入货源产地直采，保证商品品质；为满足不同顾客需求，提供“保税区发货”和“海外直邮”等购物方式，所售商品均受正品保障。</p>
        <p>正品保证：所售商品均受正品保障。</p>
        <p>一件代发：所售商品均为商家一件代发。</p>
        <p>售后无忧：我们承诺按电商法相关规定7天无忧退货。</p>
      </div>
    </div>
  </div>
</template>

<script>/**
 * @author  XuHongli
 * @date  2022/11/15 9:50
 * @version 1.0
 * @description
 */
import {mapGetters} from 'vuex'
import companyZj from './components/companyZj'

export default {
  name: 'Company',
  components: { companyZj },
  computed: {
    ...mapGetters(['webKeyword']),
    heTunInfo() {
      if (process.env.NODE_ENV === 'production') {
        return process.env.NODE_ENV
      } else if (process.env.NODE_ENV === 'pre') {
        return process.env.NODE_ENV
      } else if (process.env.NODE_ENV === 'hetun') {
        return process.env.NODE_ENV
      } else {
        return process.env.NODE_ENV
      }
    }
  },
  created() {
    console.log('环境', process.env.NODE_ENV)
  }
}
</script>

<style scoped>

</style>
