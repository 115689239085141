<template>
  <div>
    <div class="title">公司简介</div>
    <div style="text-align: center; line-height: 20px; margin-top: 10px;">
      发布时间：2022-04-11 20:19:26
    </div>
    <div data-v-0ab10f90="" style="font-size: 14px;"><p>&nbsp;</p>
      <p style="text-indent: 2em;line-height: 27px;margin-bottom: 0;">
        <span>深圳市合囤供应链有限公司成立于2021年，致力于针对私域电商、社交电商、团购、直播、盲盒、积分商城、供应链等平台，应用S2B2C帮助其降低成本，打造覆盖行业广、创新能力强的全品类综合型供应链服务商，目前公司云平台旗下合囤供应链SaaS平台是公司电商团队为企业“直播+商城+分销+供应链”的电商服务一体化解决方案。帮助客户在新零售时代实现数据化转型，帮助企业提高运营效率和盈利能力。</span>
      </p>
      <p style="margin-top: 0;line-height: 27px;">
        <span>精心打造品牌引进、团购选品、低折扣专区、企业福利、引流爆品等多场景化专区为客户提供个性标签化服务，公司目前拥有10万+SKU、品牌覆盖面广，涵盖全品类主流产品，以丰富的全品类商品协助商家链接服务用户。</span>
      </p>
      <p><strong> <span>我们的解决方案是什么</span> </strong></p>
      <p style="text-indent: 2em;"><span>链接上游品牌厂家，联合B端搭建商城用最具性价比的产品吸引C端用户，让每一次消费更具价值！</span></p>
      <p><strong> <span>深圳市合囤供应链有限责任公司愿景</span> </strong></p>
      <p style="text-indent: 2em;"><span>囤人，囤货，囤资源，合作共赢，回归商业本质。</span></p>
      <p><strong> <span>深圳市合囤供应链有限责任公司定位</span> </strong></p>
      <p style="text-indent: 2em;"><span>打破传统做小而精、专而新的供应链企业</span></p>
      <p style="text-indent: 2em;"><span>通过端到端的协同，为客户和企业创造价值。</span></p>
      <p><strong> <span>深圳市合囤供应链有限责任公司特色</span> </strong></p>
      <p style="text-indent: 2em;"><span>改变传统供应链，链接S2B2C 打造好用的交易平台</span></p>
      <p style="text-indent: 2em;"><span>线上线下:资源整合转化线上下联动经营</span></p>
      <p style="text-indent: 2em;"><span>人货厂宣:连接重构 人货场宣四维一体</span></p>
      <p style="text-indent: 2em;"><span>服务营销:工具、销售、营销、运营全链条整合承载</span></p>
      <p style="text-indent: 2em;"><span>内部外部:经营管理流转 平台统一</span></p>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'heTunCompanyZj',
  computed: {
    ...mapGetters(['webKeyword'])
  }
}
</script>

<style scoped>

</style>
